<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement ideaMenu secondaryBG border-bottom">
            <div class="container">
                <div class="row">
                    <ul class="col-12 list-scrollbar d-flex align-items-center justify-content-center mb-0">
                        <li class="nav-item" @click="classification = 'All';changeRoute()">
                            <a class="nav-link" href="javascript:void(0)"
                                :class="[{'active' : classification == 'All'}]">All</a>
                        </li>
                        <li class="nav-item" @click="classification = item;changeRoute()"
                            v-for="item,index in static_vars.classifications">
                            <a class="nav-link" href="javascript:void(0)"
                                :class="[{'active' : classification == item}]">{{item}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="bg-white blockElement innerPage-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadCrum d-flex align-items-center justify-content-between">
                            <div class="bdPage">
                                <h2>Trade Ideas</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item">
                                            <router-link class="green" to="/">Home</router-link>
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">Trade Ideas</li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="rightSearch hideMobile">
                                <form class="searchBar">
                                    <div class="form-group position-relative inputIcon mb-0">
                                        <input type="text" class="form-control" placeholder="Search.." name=""
                                            v-model="search" />
                                        <a class="searchButton" href="#">
                                            <vue-feather type="search"></vue-feather>
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg-white blockElement space-footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="ideaTabs d-flex align-items-center mobWrap justify-content-between border-bottom">
                            <ul class="toplinks d-flex align-items-center mb-0">
                                <li
                                    @click="type = 'Trade Idea';showCalendar = false;date = '';mostReadIdeas = false;assetFilter = '';position = '';timeInterval = '';page = 1;getIdeasList()">
                                    <a href="javascript:void(0)" :class="[{'active' : type == 'Trade Idea'}]">Trade
                                        Idea</a>
                                </li>
                                <li
                                    @click="type = 'Beyond Technical Analysis';showCalendar = false;date = '';mostReadIdeas = false;assetFilter = '';position = '';timeInterval = '';page = 1;getIdeasList()">
                                    <a href="javascript:void(0)"
                                        :class="[{'active' : type == 'Beyond Technical Analysis'}]">Beyond
                                        Technicals</a>
                                </li>
                                <li
                                    @click="type = 'Educational';showCalendar = false;date = '';mostReadIdeas = false;assetFilter = '';page = 1;position = '';timeInterval = '';getIdeasList()">
                                    <a href="javascript:void(0)"
                                        :class="[{'active' : type == 'Educational'}]">Educational</a>
                                </li>
                            </ul>

                            <div class="portIdea d-flex mobWrap">
                                <div class="filterRemove">
                                    <span v-if="symbol">{{symbol}}<a href="javascript:void(0)"
                                            @click="symbol = '';assetFilter = '';position = '';timeInterval = '';getIdeasList()">
                                            <vue-feather type="x"></vue-feather>
                                        </a></span>
                                    <!--  <span v-if="filterObject.time">{{filterObject.time}}<a href="javascript:void(0)"
                                        @click="filterObject.time = '';remove()">
                                        <vue-feather type="x"></vue-feather>
                                    </a></span>
                                    <span v-if="filterObject.position">{{filterObject.position}}<a href="javascript:void(0)"
                                        @click="filterObject.position = '';remove()">
                                        <vue-feather type="x"></vue-feather>
                                    </a></span> -->
                                </div>
                                <div class="grphBtn mobWrap transparent position-relative">

                                    <select class="form-control form-select border-select me-md-2 f-12 smallBtn"
                                        v-model="assetFilter"
                                        @change="showCalendar = false;date = '';mostReadIdeas = false;page=1;getIdeasList()">
                                        <option value=''>All</option>
                                        <option v-for="item,index in static_vars.tradeIdeasFilter" :key="index"
                                            :value="item">{{item}}</option>
                                    </select>
                                    <select class="form-control form-select border-select me-md-2 f-12 smallBtn" v-model="position"
                                        @change="showCalendar = false;date = '';mostReadIdeas = false;page=1;getIdeasList()">
                                        <option value=''>All</option>
                                        <option v-for="item,index in positions" :key="index" :value="item">{{item}}
                                        </option>
                                    </select>
                                    <select class="form-control form-select border-select me-md-2 f-12 smallBtn"
                                        v-model="timeInterval"
                                        @change="showCalendar = false;date = '';mostReadIdeas = false;page=1;getIdeasList()">
                                        <option value=''>All</option>
                                        <option v-for="item,index in intervalTitle" :key="index" :value="index">{{item}}
                                        </option>
                                    </select>

                                    <!-- <a href="javascript:void(0)"><vue-feather type="clock"></vue-feather> </a> -->
                                    <a href="javascript:void(0)"
                                        @click="mostReadIdeas = !mostReadIdeas;assetFilter = '';position = '';timeInterval = '';showCalendar = false;date = '';page=1;getIdeasList()" :class="[{'active' : mostReadIdeas}]"><img
                                            src="assets/images/fire.svg" alt="Grid" title="Trending">
                                    </a>
                                    <Datepicker :range="true" v-model="date" :multiCalendars="true" inline autoApply
                                        :multiCalendarsSolo="true" :enableTimePicker="false" ref="datepicker"
                                        :maxDate="new Date()" v-if="showCalendar" @update:modelValue="handleDate" />
                                    <a class="me-0 filter-dropdown" href="javascript:void(0)"
                                        @click="date = '';showCalendar = !showCalendar;assetFilter = '';position = '';timeInterval = '';mostReadIdeas = false"
                                        v-if="!showCalendar">
                                        <vue-feather type="calendar"></vue-feather>
                                    </a>
                                    <a class="me-0" href="javascript:void(0)" @click="date = '';showCalendar = false"
                                        v-else>
                                        <vue-feather type="x"></vue-feather>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row py-3 py-xl-0" v-if="showTradeIdea.length">
                    <div class="col-12 col-lg-4 mb-4" v-for="item,key in showTradeIdea">
                        <div class="card border-0 midNew h-100" :id="'trade'+item.trade_id">
                            <div class="card-body pointer spaceBetween">
                                <div class="upText">
                                    <h4 class="card-title"><a href="javascript:void(0)"
                                            @click=" () => goToDetailPage(item)">{{item.title}}</a> <span
                                            class="modifyDate">{{formatDate(item.created)}}</span></h4>
                                    <img :src="imageLink+'/get/doc?type=TRADE&doc='+item.document+'&id='+item.user_id"
                                        class="img-fluid" alt="" />
                                    <div
                                        class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                                        <div class="forexnood d-flex align-items-center"
                                            @click="() => goToProfilePage(item.user_id)"><!-- <img
                                                src="assets/images/forexnoob.jpg" alt="" /> --> 
                                                <div class="flex-shrink-0">
                                                    <p class="IdeaUsername mb-0">{{item.firstname.charAt(0) || ''}}{{item.lastname.charAt(0) || ''}}</p>
                                                </div><span
                                                class="mx-2">{{item.firstname || ''}} {{item.lastname || ''}}</span>
                                            <!-- <img src="assets/images/badge.jpg" alt="" /> -->
                                        </div>
                                        <div class="currency-wrap">
                                            <span class="currency"><a href="javascript:void(0)"
                                                    @click="symbol = item.symbol;getIdeasList()">{{item.symbol ||
                                                    'N/A'}}</a></span> <span class="monthly"><a
                                                    href="javascript:void(0)"
                                                    @click="timeInterval = item.time_interval;getIdeasList()">{{item.time_interval
                                                    || 'N/A'}}</a></span>
                                            <span class="short">
                                                <a href="javascript:void(0)"
                                                    @click="position = item.position;getIdeasList()"
                                                    :class="SymbolClass[item.position.toLowerCase()]"><i
                                                        data-feather="arrow-down-right"></i> {{item.position ||
                                                        'N/A'}}</a>
                                            </span>
                                        </div>
                                    </div>
                                    <p>{{item.description}}</p>
                                </div>
                                <div class="downText">
                                    <div class="smallbtn d-flex align-items-center"
                                        @click="classification= item.classification;getIdeasList()"><span
                                            class="cardEelement">{{item.classification
                                            || 'N/A'}}</span>
                                        <span class="cardEelement" @click="type= item.type;getIdeasList()">{{item.type
                                        || 'N/A'}}</span>
                                    </div>
                                    <div
                                        class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                                        <div class="d-flex align-items-center">
                                            <span @click="() => likeIdea(item,'like')" class="pointer"><i :class="parseInt(item.is_like) == 0 ? 'fa fa-heart-o' : 'fa fa-heart'"
                                                    aria-hidden="true"></i> {{item.likes_count ||
                                                    0}}</span>
                                                    <!-- <a title="Follow" class="followUser d-flex border-start ps-2 ms-2 ps-2" href="javascript:void(0)"><vue-feather type="user-plus" size="18"></vue-feather></a> -->
                                        </div>
                                        <div class="righticons">
                                            <span @click="() => likeIdea(item,'comment')"><i class="fa fa-comments-o"
                                                    aria-hidden="true"></i>{{item.comment_count || 0}}</span> 
                                                    <span><i
                                                    class="fa fa-share-alt" aria-hidden="true"></i> {{item.share_count
                                                    || 0}}</span> 
                                                    <span><i class="fa fa-bookmark-o" aria-hidden="true"></i>
                                                {{item.bookmarks_count || 0}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center mt-4"
                        v-if="store.tradeIdeasList.records.length < store.tradeIdeasList.totalRecords">
                        <p class="grd_btn" v-if="store.loading"><i class="me-2 fa fa-spinner fa-spin"></i></p>
                        <a href="javascript:void(0)" class="grd_btn" @click="loadMore()" v-else> Load More</a>
                    </div>
                </div>
                <no-record v-else></no-record>
            </div>
        </section>
        <login v-if="showPopup"></login>

        <!-- Open Modal -->
        <!-- <div class="modal fade ideaModal" :class="[{'show' : id != ''}]" v-if="id != ''">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="row modal-content g-0 h-100">
            <div class="col-12 col-lg-6 ideaChart">
                <img class="d-block w-100 h-100" :src="imageLink+'/get/doc?type=TRADE&doc='+id_data(id).document+'&id=1'" alt="Chart" title="Deep Value and Bullish Sentiment in La-Z-Boy">
            </div>  
            <div class="col-12 col-lg-6 d-flex flex-column ideaChart border-start">
                <div class="modal-body p-4 pt-0 border-0">
                    <div class="d-flex align-items-center justify-content-between modalHeader py-3">
                        <ul class="d-flex align-items-center mb-0">
                            <li><a href="javascript:void(0)" class="d-flex me-3"><vue-feather size="23px" type="share-2"></vue-feather></a></li>
                            <li><a href="javascript:void(0)" class="d-flex "><vue-feather size="23px" type="printer"></vue-feather></a></li>
                        </ul>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="id = ''"></button>
                    </div>
                    <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                        <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">{{id_data(id).firstname || ''}} {{id_data(id).lastname || ''}}</span> <img src="assets/images/badge.jpg" alt="" /></div>
                        <div class="currency-wrap">
                            <span class="currency"><a href="#">{{id_data(id).symbol || 'N/A'}}</a></span> <span class="monthly"><a href="#">{{id_data(id).time_interval || 'N/A'}}</a></span>
                            <span class="short">
                                <a href="#" :class="SymbolClass[id_data(id).position.toLowerCase()]"><i data-feather="arrow-down-right"></i> {{id_data(id).position || 'N/A'}}</a>
                            </span>
                        </div>
                    </div>
                    <h2 class="medium my-4 py-2">{{id_data(id).title || ''}}</h2>
                    <p>{{id_data(id).description || ''}}</p>
                </div>
                <div class="modalFooter p-0 border-0">
                    <h2 class="medium px-4 pb-3 pt-2">Comments</h2>
                    <div class="prevNextNews border-top trade d-flex align-items-center justify-content-between">
                        <a href="javascript:void(0)" class="border-end prev d-flex align-items-center" @click="next_prev(-1)"><i class="me-2 fa fa-caret-left" aria-hidden="true"></i>Previous</a>
                        <a href="javascript:void(0)" class="border-start prev d-flex align-items-center" @click="next_prev(1)">Next <i class="ms-2 fa fa-caret-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div> -->
        <!-- End -->
    </div>
</template>

<script>
import { useMainStore } from "@/store";
import moment from 'moment'
import bannerslider from "../../components/shared/banner_slider";
import { Form, Field, ErrorMessage } from "vee-validate";
import login from "../../components/shared/userlogin"
import $ from "jquery";
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            page: 1,
            perPage: 12,
            type: 'Trade Idea',
            classification: 'All',
            imageLink: process.env.VUE_APP_API_BASE_URL,
            search: '',
            date: '',
            showCalendar: false,
            from: '',
            to: '',
            assetFilter: '',
            mostReadIdeas: false,
            filterObject: {
                'symbol': '',
                'time': '',
                'position': '',
            },
            showDetail: {},
            id: '',
            SymbolClass: {
                'neutral': 'yellowBG',
                'buy': 'greenBG',
                'sell': 'redBG'
            },
            showPopup: '',
            position: '',
            timeInterval: '',
            positions: ["Buy", "Sell", "Neutral"],
            intervalTitle: {
                "1M": "1 Minute",
                "5M": "5 Minute",
                "15M": "15 Minute",
                "1H": "1 Hour",
                "4H": "4 Hour",
                "1D": "1 Day",
                "1W": "1 Week",
                "1MN": "1 Month",
            },
            symbol: ''
        }
    },
    // watch : {
    //     'id'(val){
    //         const el = document.body;
    //         if(val){
    //             if (el.classList.contains('position-fixed')) {
    //               el.classList.remove('position-fixed');
    //             } else {
    //               el.classList.add('position-fixed');
    //             }
    //         }else{
    //             el.classList.remove('position-fixed');
    //         }
    //     }
    // },
    components: {
        bannerSlider: bannerslider,
        Form,
        Field,
        ErrorMessage,
        login

    },
    computed: {
        showTradeIdea() {
            if (this.store.tradeIdeasList.totalRecords && this.store.tradeIdeasList.totalRecords > 0) {
                if (this.search != '') {
                    return this.store.tradeIdeasList.records.filter(val => {
                        return (val.title.toLowerCase().includes(this.search.trim().toLowerCase()) || val.description.toLowerCase().includes(this.search.trim().toLowerCase()) || (val.firstname.toLowerCase().includes(this.search.trim().toLowerCase())) || (val.symbol.toLowerCase().includes(this.search.trim().toLowerCase())))
                    })
                } else {
                    // let data = this.store.tradeIdeasList.records
                    // let classData = [];
                    // if (this.filterObject.symbol != '') {
                    //     data = data.filter(item => item.symbol.toLowerCase() == this.filterObject.symbol.toLowerCase())
                    // }
                    // if (this.filterObject.time != '') {
                    //     data = data.filter(item => item.time_interval.toLowerCase() == this.filterObject.time.toLowerCase())
                    // }
                    // if (this.filterObject.position != '') {
                    //     data = data.filter(item => item.position.toLowerCase() == this.filterObject.position.toLowerCase())
                    // }
                    // if (this.classification == 'All') {
                    //     classData = this.store.tradeIdeasList.records
                    // } else if (this.classification != 'All') {
                    //     for (let item of this.static_vars.classifications) {
                    //         if (item == this.classification) {
                    //             data = data.filter(val => val.classification == item).map(val => val)
                    //         }
                    //     }
                    // }
                    return this.store.tradeIdeasList.records
                }/*else{
                if(this.classification == 'All'){
                    return this.store.tradeIdeasList.records
                }else{
                    for (let item of this.static_vars.classifications) {
                        if(item == this.classification){
                            return this.store.tradeIdeasList.records.filter(val => val.classification == item).map(val => val)
                        }
                    }
                }

            }*/
            } else {
                return []
            }
        }
    },
    methods: {
        goToProfilePage(id) {
            if (this.store.user.profile) {
                if (parseInt(this.store.user.profile.user_id) != parseInt(id)) {
                    this.$router.push({
                        name: 'profile',
                        query: { 'id': id }
                    });
                } else {
                    this.$router.push({
                        name: 'profile',
                    });
                }
            } else {
                this.$router.push({
                    name: 'profile',
                    query: { 'id': id }
                });
            }
        },
        likeIdea(item, type) {
            if (Object.keys(this.store.user).length > 0 && this.store.user.profile) {
                this.showPopup = ''
                if (type == 'like') {
                    this.callUpdateTrade(item)
                } else {
                    this.goToDetailPage(item)
                }
            } else {
                this.showPopup = item.trade_id
            }
        },
        callUpdateTrade(item) {
            let form = {
                "type": "TRADE", "is_like": 1, "user_id": this.store.user.profile.user_id, "target_id": item.trade_id
            }
            if (parseInt(item.is_like) == 1) {
                form['is_like'] = 0
            }
            if (this.store.tradeIdeasList.totalRecords) {
                this.store.tradeIdeasList.records.forEach(val => {
                    if (parseInt(val.trade_id) == parseInt(item.trade_id)) {
                        if (parseInt(val.is_like) == 1) {
                            val.is_like = 0
                            val.likes_count = parseInt(val.likes_count) - 1
                        } else {
                            val.is_like = 1
                            val.likes_count = parseInt(val.likes_count) + 1
                        }
                    }
                })
            }
            this.store.callUpdateIdeas(form, true)
        },
        toDivElement(id) {
            try {
                setTimeout(function () {
                    var elmnt = document.getElementById(id);
                    if (elmnt != null) {
                        elmnt.scrollIntoView({ behavior: "smooth" });
                        document.getElementById(id).focus();
                        //var top = $(window).scrollTop()
                        // console.log(id,'top',top)
                        // $(window).scrollTop(top-80)
                    }
                }, 500);
            } catch (e) { }
        },
        goToDetailPage(item) {
            let title = item.title
            if (title.includes('-')) {
                title = title.replace(/-+/g, '')
            } else {
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if (titl.includes('--')) {
                    title = titl.replace(/--+/g, '-')
                } else {
                    title = item.title
                }
            }
            this.$router.push({
                name: 'details',
                params: { 'id': item.trade_id, 'title': title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'), 'page': this.page }
            });
            /*/ +/g, '-'*/
        },
        remove() {
            let query = Object.assign({}, this.$route.query);
            if (this.filterObject.symbol == '') {
                delete query['symbol']
            }
            if (this.filterObject.time == '') {
                delete query['time']
            }
            if (this.filterObject.position == '') {
                delete query['position']
            }
            this.$router.replace({
                name: 'trade_ideas',
                query: query
            });
        },
        changeRoute() {
            let query = {};
            if (this.classification != 'All') {
                query = { 'type': this.type, 'classification': this.classification }
            } else {
                query = { 'type': this.type }
            }
            if (this.filterObject.symbol != '') {
                query = { ...query, 'symbol': this.filterObject.symbol }
            }
            if (this.filterObject.time != '') {
                query = { ...query, 'time': this.filterObject.time }
            }
            if (this.filterObject.position != '') {
                query = { ...query, 'position': this.filterObject.position }
            }
            this.$router.replace({
                name: 'trade_ideas',
                query: query
            });
            this.page = 1
            this.getIdeasList()
        },
        id_data(id) {
            if (this.showTradeIdea.length > 0) {
                return this.showTradeIdea.find(val => val.trade_id == id) || {}
            }
        },
        id_index(id) {
            if (this.showTradeIdea.length > 0) {
                return this.showTradeIdea.map(val => val.trade_id).indexOf(id)
            }
        },
        next_prev(to) {
            if (this.showTradeIdea.length > 0) {
                if ((this.id_index(this.id) + to) < 0) {
                    this.id = this.showTradeIdea[this.showTradeIdea.length - 1].trade_id
                } else if (this.showTradeIdea.length == this.id_index(this.id) + to) {
                    this.id = this.showTradeIdea[0].trade_id
                } else {
                    this.id = this.showTradeIdea[this.id_index(this.id) + to].trade_id
                }
            }
        },
        handleDate() {
            if (this.date[0], this.date[1]) {
                this.showCalendar = false
                //this.$refs.datepicker.closeMenu()
                this.from = moment(this.date[0]).format('YYYY-MM-DD')
                this.to = moment(this.date[1]).format('YYYY-MM-DD')
                this.getIdeasList()
            }
        },
        loadMore() {
            this.page += 1
            this.getIdeasList('loadmore')
        },
        formatDate(val) {
            return moment(val).format('DD MMM')
        },
        getIdeasList(from) {
            if (!from) {
                let query = {};
                if (this.type) {
                    query['type'] = this.type
                }
                if (this.classification && this.classification != 'All') {
                    query['classification'] = this.classification
                }
                if (this.symbol) {
                    query['symbol'] = this.symbol

                }
                if (this.timeInterval) {
                    query['interval'] = this.timeInterval

                }
                if (this.position) {
                    query['position'] = this.position

                }
                this.$router.replace({
                    name: 'trade_ideas',
                    query: query
                });
            }

            let formData = {
                page: parseInt(this.page),
                perPage: parseInt(this.perPage),
                "filter": { "trade_ideas.type": this.type },
                "sort": { "field": "trade_ideas.created", "type": "DESC" }
            };
            if (this.from && this.to) {
                formData['start_date'] = this.from
                formData['end_date'] = this.to
            }
            if (this.assetFilter != '') {
                formData.filter['trade_ideas.category'] = this.assetFilter
            }
            if (this.mostReadIdeas) {
                formData['sort'] = { "field": "trade_ideas.view_count", "type": "DESC" }
            }
            if (this.classification != 'All') {
                formData.filter['classification'] = this.classification
            }
            if (this.position) {
                formData.filter['position'] = this.position
            }
            if (this.timeInterval) {
                formData.filter['time_interval'] = this.timeInterval
            }
            if (this.symbol) {
                formData.filter['symbol'] = this.symbol
            }
            if (this.store.user.profile) {
                formData['logged_user_id'] = this.store.user.profile.user_id
            }
            this.store
                .callTradeIdeasList(formData, true)
                .then((response) => {
                })
                .catch();
        }
    },
    mounted() {
        if (this.$router.options.history.state.forward && this.$router.options.history.state.forward.split('/')[1] == 'details') {
            let data = this.$router.options.history.state.forward.split('/');
            this.toDivElement('trade' + data[2])
            this.$router.options.history.state.forward = ""
            this.page = parseInt(data[4])
        } else {
            this.page = 1
            this.getIdeasList()
        }
        const _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".filter-dropdown").length) {
                _that.showCalendar = false;
            }
        });
    },
    created() {
        if (Object.keys(this.$route.query).length > 0) {
            if (this.$route.query.type) {
                this.type = this.$route.query.type
            }
            if (this.$route.query.classification) {
                this.classification = this.$route.query.classification
            }
            if (this.$route.query.symbol) {
                this.symbol = this.$route.query.symbol

            }
            if (this.$route.query.interval) {
                this.timeInterval = this.$route.query.interval
            }
            if (this.$route.query.position) {
                this.position = this.$route.query.position
            }
        }
    }
}
</script>
<style scoped>
.modal.fade.show {
    opacity: 1;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    overflow: auto;
}
</style>
